import { createSlice } from "@reduxjs/toolkit";
type State = {
    initialRoute: string;
    initialRouteIsSet: boolean;
}
const initialRoute = createSlice({
    name: "initialRoute",
    initialState: {
        initialRoute: "",
        initialRouteIsSet: false,
    },
    reducers: {
        setInitialRouteSuccess: (state: State, action) => {
            state.initialRoute = action.payload;
            state.initialRouteIsSet = true;
        },
        clearInitalRouteSuccess: (state: State) => {
            state.initialRoute = ""
        }
    }
})
export default initialRoute.reducer;
const { setInitialRouteSuccess, clearInitalRouteSuccess } = initialRoute.actions;

export const setInitialRoute = (route : string) => async (dispatch: Function) => {
    dispatch(setInitialRouteSuccess(route))
}

export const clearInitalRoute = () => async (dispatch: Function) => {
    dispatch(clearInitalRouteSuccess())
}