import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../store";
import { useSelector } from "react-redux";
import MainNavigation from "../components/navigation/MainNavigation";
import ProfilePhoto from "../components/settings/ProfilePhoto";
import Button from "../components/ui/common/Button";
import ProfileSettings from "../components/settings/ProfileSettings";
import BrandSelector from "../components/settings/BrandSelector";
import ProfileNotifications from "../components/settings/ProfileNotifications";
import Modal from "../components/ui/modal/Modal";
import NotificationsSettingsModal from "../components/settings/NotificationsSettingsModal";
import MobileNavigation from "../components/navigation/MobileNavigation";
import { asyncLogout } from "../store/slices/auth";
import { fetchCurrentUser } from "../store/slices/currentUser";
import { fetchAlgorithms } from "../store/slices/algorithms";
import { updateCurrentUser } from "../store/slices/currentUser";
import "./Settings.scss";
import { clearInitalRoute } from "../store/slices/initialRoute";

const Settings = () => {
  const dispatch = useAppDispatch();
  const user = useSelector((state: any) => state.currentUser.data) || {};
  const brand = useSelector((state: any) => state.currentBrand.data) || {};
  const algorithms = useSelector((state: any) => state.algorithms.data) || {};
  const isMobileNavigationShown = useSelector((state: any) => state.mobile.isNavigationOpen);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(user?.imageUrl || "");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [showNotificationsModal, setShowNotificationsModal] = useState(false);

  useEffect(() => {
    document.title = "Settings - Smartocto real-time";
  }, []);

  useEffect(() => {
    if (Object.keys(user).length === 0) {
      dispatch(fetchCurrentUser());
    }

    if (Object.keys(algorithms).length === 0) {
      dispatch(fetchAlgorithms());
    }
  }, []);

  useEffect(() => {
    if (!user) return;
    setFirstName(user.name_first);
    setLastName(user.name_last);
    setProfilePhoto(user.imageUrl);
  }, [user]);

  const handleOpenNotificationModal = () => {
    setShowNotificationsModal(true);
  };

  const handleLogout = () => {
    window.localStorage.clear();
    dispatch(clearInitalRoute());
    dispatch(asyncLogout());
  };

  const handleSaveChanges = () => {
    const dataToSave = {
      ...user,
      name_first: firstName,
      name_last: lastName,
      imageUrl: profilePhoto,
    };

    if (newPassword.trim().length) {
      dataToSave.password = newPassword.trim();
    }
    dispatch(updateCurrentUser(dataToSave));
  };

  return (
    <div>
      <Modal
        isOpen={showNotificationsModal}
        onClose={() => {
          setShowNotificationsModal(false);
        }}
      >
        <NotificationsSettingsModal algorithms={algorithms} user={user} />
      </Modal>

      <MainNavigation />
      <MobileNavigation title="" />
      <div className={`settings-page-container ${isMobileNavigationShown ? "mobile-menu-shown" : ""}`}>
        <h1>
          Settings <Button onClick={handleLogout}>Sign out</Button>
        </h1>
        <div className="settings-page-content">
          <div className="settings-column">
            <ProfilePhoto imgSrc={profilePhoto} updatePhoto={setProfilePhoto} />
          </div>
          <div className="settings-column">
            <ProfileSettings
              email={user.email || ""}
              firstName={firstName || ""}
              lastName={lastName || ""}
              newPassword={newPassword}
              confirmNewPassword={confirmNewPassword}
              setFirstName={setFirstName}
              setLastName={setLastName}
              setNewPassword={setNewPassword}
              setConfirmNewPassword={setConfirmNewPassword}
              isSuperUserOrAdmin={user?.admin || user?.superUser}
              organisationIdp={brand.organisationIdp || ""}
              handleSave={handleSaveChanges}
            />
          </div>
          <div className="settings-column">
            <BrandSelector brands={user.brands} currentBrand={brand.domain || ""} />
          </div>
        </div>
        <div className="notifications-settings">
          <ProfileNotifications user={user} openNotificationsModal={handleOpenNotificationModal} />
          <div />
        </div>
      </div>
    </div>
  );
};

export default Settings;
