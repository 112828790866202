import React from "react";
import Input from "../../../../ui/common/Input";
import TextArea from "../../../../ui/common/TextArea";
import ToggleSwitch from "../../../../ui/toggleSwitch/ToggleSwitch";
import "./TentaclesSettingsForms.scss";

const TentaclesSettingsForm = ({ data, handleChanges }) => {
  return (
    <div className="inputs-container tentacles-settings-form-container">
      <Input
        label="Base Paths"
        value={data.basePaths.value || data.basePaths.default}
        onChange={(e) => {
          handleChanges("basePaths", e.target.value);
        }}
        fullWidth
        separateLines
      />
      <div className="switch-container">
        <ToggleSwitch
          checked={
            data.collectReadingtime.value !== undefined && data.collectReadingtime.value !== null
              ? data.collectReadingtime.value
              : data.collectReadingtime.default
          }
          onChange={(e) => {
            handleChanges("collectReadingtime", e);
          }}
        />
        <span className="switch-label">Collect Readingtime </span>
      </div>
      <Input
        label="Cookiewall Accept Url"
        value={data.cookiewallAcceptUrl.value || data.cookiewallAcceptUrl.default}
        onChange={(e) => {
          handleChanges("cookiewallAcceptUrl", e.target.value);
        }}
        fullWidth
        separateLines
      />
      <Input
        label="Domain Search Path"
        value={data.domainSearchPath.value || data.domainSearchPath.default}
        onChange={(e) => {
          handleChanges("domainSearchPath", e.target.value);
        }}
        fullWidth
        separateLines
      />
      {/* <Input label="Exclude Content" separateLines /> */}
      <Input
        label="Link Click Factor"
        type="number"
        value={data.linkClickFactor.value || data.linkClickFactor.default}
        onChange={(e) => {
          handleChanges("linkClickFactor", e.target.valueAsNumber);
        }}
        fullWidth
        separateLines
      />
      <div className="switch-container">
        <ToggleSwitch
          checked={
            data.linkClickOnly.value !== undefined && data.linkClickOnly.value !== null
              ? data.linkClickOnly.value
              : data.linkClickOnly.default
          }
          onChange={(e) => {
            handleChanges("linkClickOnly", e);
          }}
        />
        <span className="switch-label">Link Click Only</span>
      </div>
      <Input
        value={data.linkRegEx.value || data.linkRegEx.default}
        onChange={(e) => {
          handleChanges("linkRegEx", e.target.value);
        }}
        label="Link RegEx"
        fullWidth
        separateLines
      />
      <div className="switch-container">
        <ToggleSwitch
          checked={
            data.linkRegExOnParams.value !== undefined && data.linkRegExOnParams.value !== null
              ? data.linkRegExOnParams.value
              : data.linkRegExOnParams.default
          }
          onChange={(e) => {
            handleChanges("linkRegExOnParams", e);
          }}
        />
        <span className="switch-label">Link Reg Ex On Params</span>
      </div>
      <Input
        label="Loyalty Click30 Factor"
        value={data.loyaltyClick30Factor.value || data.loyaltyClick30Factor.default}
        onChange={(e) => {
          handleChanges("loyaltyClick30Factor", e.target.value);
        }}
        fullWidth
        separateLines
      />
      <Input
        label="Loyalty Click Factor"
        value={data.loyaltyClickFactor.value || data.loyaltyClick30Factor.default}
        onChange={(e) => {
          handleChanges("loyaltyClickFactor", e.target.value);
        }}
        fullWidth
        separateLines
      />
      {/* <Input label="Main Content" separateLines /> */}
      <Input
        label="Min Screenwidth"
        value={data.minScreenwidth.value || data.minScreenwidth.default}
        onChange={(e) => {
          handleChanges("minScreenwidth", e.target.value);
        }}
        fullWidth
        separateLines
      />
      <Input
        label="Period Minutes"
        type="number"
        value={data.periodMinutes.value || data.periodMinutes.default}
        onChange={(e) => {
          handleChanges("periodMinutes", e.target.value);
        }}
        fullWidth
        separateLines
      />
      <Input
        label="Replacement Elements"
        value={data.replacementElements.value || data.replacementElements.default}
        onChange={(e) => {
          handleChanges("replacementElements", e.target.value);
        }}
        fullWidth
        separateLines
      />
      <div className="switch-container">
        <ToggleSwitch
          checked={
            data.useDivInReplacement.value !== undefined && data.useDivInReplacement.value !== null
              ? data.useDivInReplacement.value
              : data.useDivInReplacement.default
          }
          onChange={(e) => {
            handleChanges("useDivInReplacement", e);
          }}
        />
        <span className="switch-label">Use Div In Replacement</span>
      </div>
      <div className="switch-container">
        <ToggleSwitch
          checked={
            data.useIdsInXpaths.value !== undefined && data.useIdsInXpaths.value !== null
              ? data.useIdsInXpaths.value
              : data.useIdsInXpaths.default
          }
          onChange={(e) => {
            handleChanges("useIdsInXpaths", e);
          }}
        />
        <span className="switch-label">Use Ids In Xpaths</span>
      </div><div className="switch-container">
        <ToggleSwitch
          checked={
            data.sendPageMapping.value !== undefined && data.sendPageMapping.value !== null
              ? data.sendPageMapping.value
              : data.sendPageMapping.default
          }
          onChange={(e) => {
            handleChanges("sendPageMapping", e);
          }}
        />
        <span className="switch-label">Send Page Mapping</span>
        
      </div>
      <div className="switch-container">
        <ToggleSwitch
          checked={
            data.useIngestionApi.value !== undefined && data.useIngestionApi.value !== null
              ? data.useIngestionApi.value
              : data.useIngestionApi.default
          }
          onChange={(e) => {
            handleChanges("useIngestionApi", e);
          }}
        />
        <span className="switch-label">Use Ingestion Api</span>
      </div>
      <Input
        label="Video Path"
        checked={data.videoPath.value || data.videoPath.default}
        onChange={(e) => {
          handleChanges("videoPath", e);
        }}
        fullWidth
        separateLines
      />
    </div>
  );
};

export default TentaclesSettingsForm;
